<style lang="less" scoped>
  .user-content {
    margin: 0 auto;
    width: 92%;
    .el-form-item {
      margin-bottom: 14px !important;
    }
  }
</style>
<template>
  <div class="content">
    <div class="user-content">
      <el-form ref="userForm" :model="userInfo" :rules="rules">
        <el-form-item label="登录账号:" prop="username">
          <el-input v-model="userInfo.username" placeholder="请输入登录账号"></el-input>
        </el-form-item>

        <el-form-item label="登录密码:" prop="password">
          <el-input v-model="userInfo.password" placeholder="请输入登录密码"></el-input>
        </el-form-item>

        <el-form-item class="text-right m-t-md">
          <el-button type="primary" size="large" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import cookie from '../../utils/http/cookie';
  import {API_ROOT} from '../../config';
  import {createUser, getUserById, updateUserInfo, getByUsername,updateAccPwd} from '../../services/user';
  import {searchCustomerByKey, queryBrander} from '../../services/customer';
  import {getAllRole} from '../../services/role';
  import {mapGetters, mapActions} from "vuex";
  import {Notification} from "element-ui";
  import {valiPhone, valiEmail, valiPassword} from "../../utils/validator/element";

  export default {
    data() {
      const valiAccount = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入您的账号名'));
        } else if (!/^[a-zA-Z0-9-_-]{4,30}$/.test(value)) {
          callback(new Error('账号只能由4-30位数字和字母组成!'));
        } else {
          return this.checkAccount(callback, value);
        }
      }
      return {
        loading: false, //加载框
        userInfo: {
          id: '',
          username: '',
          password: '',
        },
        rules: {
          username: [
            {validator: valiAccount, trigger: 'blur'}
          ],
          password: [
            {validator: valiPassword, trigger: 'change'}
          ]
        }
      }
    },
    computed: {
      ...mapGetters(
        {roles: 'roles'}
      ),
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    props: {
      userDetailVisible: {
        type: Boolean,
        default: function () {
          return false
        }
      },
      propUserInfo: {
        type: Object,
        default: function () {
          return {
            id: '',
            username: '',
            password: ''
          }
        }
      },
    },
    methods: {
      async checkAccount(callback, value) {
        if (this.userInfo.username === this.propUserInfo.username) {
          callback();
        } else {
          const res = await getByUsername(value);
          if(res && res.errorCode === 0 && res.data) {
            return callback(new Error('已有该账号，请重新输入'));
          } else {
            callback();
          }
        }
      },

      /**
       * [更新用户信息]
       * @return {[type]} [description]
       */
      async updateUserInfo() {
        const resData = await updateAccPwd(this.userInfo);
        if (resData.errorCode === 0 && resData.data) {
          Notification.success({
            title: '成功',
            message: '修改成功'
          });
          this.$emit('onSubmit', this.userInfo);
        }
      },

      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit() {
        this.$refs.userForm.validate((valid) => {
          if (valid) {
            this.updateUserInfo();
          }
        });
      }
    },
    async created() {
      this.userInfo = {
        id: this.propUserInfo.id,
        username: this.propUserInfo.username || '',
        password: this.propUserInfo.password || ''
      }
    },
    watch: {
      userDetailVisible(newVal, oldVal) {
        if (newVal === false) {
          this.userInfo = {
            id: '',
            username: '',
            password: ''
          };
          this.$refs.userForm.resetFields();
        } else {
          this.userInfo = {
            id: this.propUserInfo.id,
            username: this.propUserInfo.username || '',
            password: this.propUserInfo.password || ''
          }
        }
      },
    }
  }
</script>
