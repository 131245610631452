<style lang="less" scoped>
  .user-content {
    margin: 0 auto;
    width: 92%;
    .el-form-item {
      margin-bottom: 14px !important;
    }
  }
</style>
<template>
  <div class="content">
    <div class="user-content">
      <el-form ref="userForm" :model="userInfo" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="系统账号:" prop="username">
              <el-input
                :disabled="isEdit"
                v-model="userInfo.username"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属角色:" prop="roleId">
              <el-select
                size="50%"
                class="inline-input w-full"
                v-model="userInfo.roleId"
                clearable
                placeholder="请选择用户角色"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="真实姓名:" prop="name">
              <el-input
                v-model="userInfo.name"
                placeholder="请输入您的真实姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话:" prop="mobile">
              <el-input
                :maxlength="11"
                v-model="userInfo.mobile"
                placeholder="请输入您的手机号码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="所属单位:" prop="customerId">
              <el-select
                class="inline-input w-full"
                v-model="userInfo.customerId"
                clearable
                remote
                filterable
                placeholder="请选择所属单位"
                :remote-method="queryCustomers"
                :loading="loading"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备　　注:" prop="remark">
              <el-input
                v-model="userInfo.remark"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item class="text-right m-t-md">
          <el-button type="primary" size="large" @click="onSubmit">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import defaultImg from "../../assets/images/defaultImg.jpg";
  import cookie from "../../utils/http/cookie";
  import { API_ROOT } from "../../config";
  import {
    createUser,
    getUserById,
    updateUserInfo,
    getByUsername,
  } from "../../services/user";
  import { getAllCustomers } from "../../services/customer";
  import { getAllRole } from "../../services/role";
  import { mapGetters, mapActions } from "vuex";
  import { Notification } from "element-ui";
  import {
    valiPhone,
    valiEmail,
    valiPassword,
  } from "../../utils/validator/element";

  export default {
    data() {
      const valiCustomer = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("必须选择一项"));
        } else {
          callback();
        }
      };
      const valiName = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("不能为空"));
        } else if (value.length > 30 || value.length < 2) {
          callback(new Error("姓名只能由2-30位字符组成!"));
        } else {
          callback();
        }
      };
      const valiAccount = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入您的账号名"));
        } else if (!/^[a-zA-Z0-9-_-]{4,30}$/.test(value)) {
          callback(new Error("账号只能由4-30位数字和字母组成!"));
        } else {
          return this.checkAccount(callback, value);
        }
      };
      return {
        customerList: [],
        roleList: [],
        loading: false, //加载框
        isDIs: false,
        isCreateAdmin: false, //是否创建的是管理员
        isFirstOpen: true,
        userInfo: {
          id: "",
          username: "",
          password: "123456",
          name: "",
          mobile: "",
          roleId: "",
          customerId: "",
          customerName: "",
          remark: "",
        },
        pager: {
          size: 999,
          page: 1,
        },
        rules: {
          username: [{ validator: valiAccount, trigger: "blur" }],
          password: [{ validator: valiPassword, trigger: "change" }],
          mobile: [{ validator: valiPhone, trigger: "blur" }],
          customerId: [{ validator: valiCustomer, trigger: "change" }],
          roleId: [{ validator: valiCustomer, trigger: "change" }],
          name: [{ validator: valiName, trigger: "blur" }],
        },
      };
    },
    components: {
      // 'other-component':OtherComponent,
    },
    computed: {
      ...mapGetters({ roles: "roles" }),
      ...mapGetters({ user: "userInfo" }),
    },
    props: {
      userDetailVisible: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      isEdit: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      propUserInfo: {
        type: Object,
        default: function() {
          return {
            id: "",
            username: "",
            password: "123456",
            name: "",
            mobile: "",
            roleId: "",
            customerId: "",
            customerName: "",
            remark: "",
          };
        },
      },
    },
    methods: {
      async checkAccount(callback, value) {
        if (this.isEdit) {
          return callback();
        }
        const res = await getByUsername(value);
        if (res && res.errorCode === 0 && res.data) {
          return callback(new Error("已有该账号，请重新输入"));
        } else {
          callback();
        }
      },
      /**
       * 根据关键字查询企业  input输入的方式
       */
      async queryCustomers(key) {
        let params = { key };
        const choosedRoleItem = this.roleList.find((roleItem) => {
          return roleItem.id == this.userInfo.roleId;
        });

        const responseData = await getAllCustomers(params, this.pager);
        if (responseData.errorCode === 0) {
          this.customerList =
            (responseData.data && responseData.data.list) || [];
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 初始化所属单位列表
       */
      initCustomerList(item) {
        if (this.isEdit) {
          if (!this.isFirstOpen) {
            this.userInfo.customerId = "";
          }
        } else {
          this.userInfo.customerId = "";
        }

        if (this.userDetailVisible) {
          const choosedRoleItem = this.roleList.find((roleItem) => {
            return roleItem.id == this.userInfo.roleId;
          });
          if (choosedRoleItem.code.toLowerCase() == "admin") {
            this.isCreateAdmin = true;
          } else {
            this.isCreateAdmin = false;
          }
          this.customerList = [];
          this.queryCustomers("");
          this.isFirstOpen = false;
        }
      },
      /**
       * 权限判断；
       */
      checkPermission() {
        let userRole = this.user.roleCode;
        // let roleId = this.user.roleId;
        let temp1 = [];
        let temp2 = [];
        if (/Admin/.test(userRole)) {
          this.roles.forEach((item) => {
            item.code.includes("SuperAdmin") || item.code.includes("user")
              ? (item.disabled = true)
              : (item.disabled = false);
            this.roleList.push(item);
          });
        } else if (/bigScrean/.test(userRole)) {
          this.roleList = this.roles.filter((item) =>
            item.code.includes("bigScrean")
          );
        } else {
          this.roles.forEach((item) => {
            //过滤掉SuperAdmin 以及 Proxy
            if (item.code.search(/(Admin|Proxy)/) === -1) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
            console.log(item);
            this.roleList.push(item);
          });
          this.roleList.forEach((item) => {
            item.disabled ? temp2.push(item) : temp1.push(item);
          });
          this.roleList = temp1.concat(temp2);
        }
      },

      /**
       * [更新用户信息]
       * @return {[type]} [description]
       */
      async updateUserInfo() {
        // alert(1)
        // console.log(this.selectStores);
        let userInfoData = null;
        //如果是创建管理员，把虚设id干掉；
        if (this.userInfo.customerId === 1) {
          this.userInfo.customerId = null;
        }
        //this.userInfo.customerId = this.customer.id;
        //this.userInfo.roleId = this.role.id;
        //this.userInfo.teamId = this.team.id;
        if (this.isEdit) {
          console.log(this.userInfo);
          userInfoData = await updateUserInfo(this.userInfo);
        } else {
          const userInfo = Object.assign({}, this.userInfo);
          delete userInfo.id;
          console.log(userInfo);
          userInfoData = await createUser(userInfo);
        }
        if (userInfoData && userInfoData.errorCode === 0 && userInfoData.data) {
          if (
            typeof userInfoData.data.result !== "undefined" &&
            !userInfoData.data.result
          ) {
            Notification.error({
              title: "失败",
              message: userInfoData.data.errMsg,
            });
          } else {
            this.$emit("onSubmit", userInfoData.data);
            Notification.success({
              title: "成功",
              message: this.isEdit ? "修改用户信息成功" : "添加用户成功",
            });
          }
        }
      },
      /**
       * 获取所有的角色
       */
      async queryAllRoles() {
        const res = await getAllRole(null, { size: 999 });
        if (res.errorCode === 0 && res.data) {
          console.warn(res.data);
          this.roleList = res.data.list || [];
        }
      },
      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit() {
        this.$refs.userForm.validate((valid) => {
          if (valid) {
            this.updateUserInfo();
          }
        });
      },
    },
    async created() {
      // 初始化数据

      this.userInfo = Object.assign({}, this.propUserInfo);
      if (this.user.roleCode.indexOf("brands") > -1) {
        // this.userInfo.customerId = this.user.customerId;
        this.userInfo.roleId = "58f730aa8e9a1555560cb57e";
        this.isDIs = true;
      }
      if (this.isEdit) {
        if (this.userInfo.roleId == "596ad1f48c313f0b9a00be4c") {
          this.isCreateAdmin = true;
        }
      }
      this.queryAllRoles();
      this.queryCustomers("");
      //提前初始化选项，让其选项在focus的时候就获取到
    },
    watch: {
      userDetailVisible(newVal, oldVal) {
        if (newVal === false) {
          this.isFirstOpen = true;
          this.isEdit = false;
          this.userInfo = {
            id: "",
            username: "",
            password: "123456",
            name: "",
            mobile: "",
            roleId: "",
            customerId: "",
            customerName: "",
            remark: "",
          };
          if (this.user.role && this.user.role.indexOf("brands") > -1) {
            // this.userInfo.customerId = this.user.customerId;
            this.userInfo.roleId = "58f730aa8e9a1555560cb57e";
            this.isDIs = true;
          }
          this.$refs.userForm.resetFields();
        } else {
          //this.queryCustomers('');
        }
      },
      userInfo(newVal) {
        // console.log(newVal);
      },
      propUserInfo(newVal) {
        if (newVal && this.isEdit) {
          this.userInfo = Object.assign({}, newVal);
          // console.log(this.userInfo)
          // if (this.propUserInfo.customerId) {
          //   this.customerList = [{id: this.propUserInfo.customerId, value: this.propUserInfo.customerName}]
          // } else {
          //   this.customerList = [{id: null, value: '威霖集团'}]
          // }
        }
      },
    },
  };
</script>
