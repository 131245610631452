/**
 * Created by xinsw on 16/9/9.
 */
import * as API from "@/utils/http/api";
import { PAGE_SIZE } from "@/config";

/* *********** 角色 *************/

/**
 * 获取所有的角色列表
 * @returns {Promise.<*>}
 */
export async function getAllRole(params, pager = { size: PAGE_SIZE, page: 1 }) {
  try {
    return await API.get("role", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建一个角色
 * @param role Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function createRole(role) {
  try {
    return await API.post("role", role);
  } catch (error) {
    return error;
  }
}

/**
 * 修改一个角色
 * @param role Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function updateRole(role) {
  try {
    return await API.post("role/update", role);
  } catch (error) {
    return error;
  }
}

/**
 * 删除角色
 * @param ids String
 * @returns {Promise.<*>}
 */
export async function removeRole(ids) {
  try {
    return await API.del("role/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取角色的信息
 * @param id String 角色ID
 * @returns {Promise.<*>}
 */
export async function getRoleById(id) {
  try {
    return await API.get("role/" + id);
  } catch (error) {
    return error;
  }
}

/* *********** 权限 *************/

/**
 * 获取所有的权限
 * @returns {Promise.<*>}
 */
export async function getAllPermissions() {
  try {
    return await API.get("permission/detail", { size: 999, sort: "entity" });
  } catch (error) {
    return error;
  }
}

// 获取当前用户所有权限列表信息
export async function getCurrentPerms() {
  try {
    return await API.get("permission/info");
  } catch (error) {
    return error;
  }
}

/**
 * 获取角色权限
 * @returns {Promise.<*>}
 */
export async function getRolePms(roleId) {
  try {
    return await API.get("permission/byRoleId/" + roleId);
  } catch (error) {
    return error;
  }
}
