<style lang="less" scoped>
  @import "../../assets/css/variables";

  .user-detail {
    // width: 40%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }

  .weiInfo {
    .el-row {
      margin-bottom: 15px;
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">
        用户管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user/list' }">
        用户列表
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getUserByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <!-- <el-input style="width: 280px" v-model="filters.username" placeholder="系统账号/真实姓名/联系号码/所属单位"></el-input> -->
            <el-input
              style="width: 280px"
              v-model="filters.key"
              placeholder="系统账号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="filters.source" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: '', label: '所有' },
                  { value: 'system', label: '系统' },
                  { value: 'wechat', label: '微信' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item v-show="false">
            <el-input style="width: 200px"></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="getUserByName">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="userList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <span>
              {{ scope.$index + pager.size * (pager.page - 1) + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="username"
          :show-overflow-tooltip="true"
          label="系统账号"
          min-width="110"
          sortable
        ></el-table-column>
        <el-table-column
          prop="roleName"
          width="100"
          label="角色"
        ></el-table-column>
        <el-table-column
          prop="name"
          width="90"
          :show-overflow-tooltip="true"
          label="微信昵称"
        ></el-table-column>
        <el-table-column
          prop="source"
          width="90"
          :show-overflow-tooltip="true"
          label="用户类型"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.source == "system" ? "系统" : "微信" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          width="120"
          label="联系号码"
        ></el-table-column>
        <el-table-column
          prop="customerName"
          min-width="100"
          :formatter="formatCompany"
          :show-overflow-tooltip="true"
          label="所属单位"
        ></el-table-column>
        <!--         <el-table-column prop="loginDate" width="160" :show-overflow-tooltip="true" :formatter="formatTime"
                         label="最后活动时间">
        </el-table-column> -->
        <el-table-column
          class-name="el-column-ellipsis"
          min-width="68"
          prop="remark"
          label="备注"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" width="410">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.roleCode === 'SuperAdmin' || !editPermission"
              size="small"
              @click="handleEidtAcc(scope.$index, scope.row)"
            >
              修改账号密码
            </el-button>
            <el-button
              :disabled="scope.row.roleCode === 'SuperAdmin' || !editPermission"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <router-link
              :to="{
                path: 'list/info/' + scope.row.id,
                query: { searchKey: filters.key ? filters.key : '' },
              }"
            >
              <el-button type="primary" size="small" class="m-l-xs">
                详情
              </el-button>
            </router-link>
            <el-button
              :disabled="scope.row.roleCode === 'SuperAdmin' || !editPermission"
              size="small"
              type="info"
              class="m-l-xs"
              @click="handleResetPwd(scope.$index, scope.row)"
            >
              重置
            </el-button>
            <el-button
              v-if="!scope.row.locked"
              type="warning"
              size="small"
              :disabled="scope.row.roleCode === 'SuperAdmin' || !editPermission"
              class="m-l-xs"
              @click="handleDisabled(scope.$index, scope.row, true)"
            >
              禁用
            </el-button>
            <el-button
              v-if="scope.row.locked"
              type="success"
              size="small"
              :disabled="scope.row.roleCode === 'SuperAdmin'"
              class="m-l-xs"
              @click="handleDisabled(scope.$index, scope.row, false)"
            >
              启用
            </el-button>
            <el-button
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              :disabled="scope.row.roleCode === 'SuperAdmin' || !editPermission"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          :disabled="!editPermission"
          type="primary"
          @click="userDetailVisible = true"
        >
          新增用户
        </el-button>
        <el-button
          type="danger"
          @click="batchRemove"
          :disabled="this.sels.length === 0 || !editPermission"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog title="修改账号密码" size="small" :visible.sync="editAccVisible">
      <edit-acc
        :propUserInfo="userInfo"
        @onSubmit="editAccEnd"
        :userDetailVisible="editAccVisible"
      ></edit-acc>
    </el-dialog>

    <el-dialog
      :title="isEdit ? '修改用户信息' : '新增用户'"
      size="small"
      :visible.sync="userDetailVisible"
    >
      <user-detail
        :isEdit="isEdit"
        :propUserInfo="userInfo"
        v-on:onSubmit="userDetailSubmit"
        :userDetailVisible="userDetailVisible"
      ></user-detail>
    </el-dialog>
    <!-- 微信详情 -->
    <el-dialog
      class="weiInfo"
      title="微信详情"
      size="small"
      :visible.sync="weiInfoShow"
    >
      <el-row>
        <el-col :span="12">
          <span>真实姓名：</span>
          <span>{{ weiInfo.name || "--" }}</span>
        </el-col>
        <el-col :span="12">
          <span>电话号码：</span>
          <span>{{ weiInfo.mobile || "--" }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <span>建筑面积：</span>
          <span>{{ weiInfo.houseArea || "--" }}</span>
        </el-col>
        <el-col :span="12">
          <span>设备位置：</span>
          <span>{{ weiInfo.cityName || "--" }}</span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import {
    searchUser,
    removeUser,
    disabledUser,
    enabledUser,
    resetUserPwd,
  } from "../../services/user";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import UserDetail from "./detail.vue";
  import EditAcc from "./editacc.vue";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {
        editPermission: true,
        isEdit: false,
        loaded: false,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 0,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        userList: [],
        filters: {
          key: "",
          source: "",
        },
        weiInfoShow: false,
        weiInfo: {}, //显示微信端所填内容
        userInfo: {
          username: "",
          password: "123456",
          name: "",
          mobile: "",
          roleId: "",
          customerId: "",
          customerName: "",
          remark: "",
        },
        listLoading: false,
        editAccVisible: false,
        userDetailVisible: false,
        sels: [], //列表选中列
      };
    },
    components: {
      EditAcc,
      "user-detail": UserDetail,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      /**
       * 用户编辑权限判断
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "User:update";
        });
      },
      formatCompany(row) {
        return row && row.customerName ? row.customerName : "威霖集团";
      },
      formatTime(row) {
        return row && row.loginDate
          ? moment(row.loginDate).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeUser(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryUsers();
              }
            });
          })
          .catch(() => {});
      },

      //跳转到详情
      handleInfo(item) {
        this.weiInfoShow = true;
        this.weiInfo = item;
      },

      handleEidtAcc(index, row) {
        this.editAccVisible = true;
        this.userInfo = row;
      },

      //跳转到编辑
      handleEdit: function(index, row) {
        this.userDetailVisible = true;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedUser = Object.assign({}, row);
        this.userInfo = {
          id: choosedUser.id,
          username: choosedUser.username,
          password: "123456",
          name: choosedUser.name,
          mobile: choosedUser.mobile,
          roleId: choosedUser.roleId,
          customerId: choosedUser.customerId,
          customerName: choosedUser.customerName,
          remark: choosedUser.remark,
        };
      },

      /**
       * 重置密码
       */
      handleResetPwd(index, row) {
        this.$confirm("确认重置该用户的密码吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            resetUserPwd(row.id, "123456").then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0 && res.data && res.data.result === true) {
                Notification.success({
                  title: "成功",
                  message: "重置密码成功",
                });
              }
            });
          })
          .catch(() => {});
      },

      editAccEnd(userData) {
        this.queryUsers();
        this.editAccVisible = false;
      },

      /**
       *用户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      userDetailSubmit(userData) {
        this.userDetailVisible = false;
        this.queryUsers();
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeUser(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryUsers();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据用户名筛选
       */
      getUserByName() {
        this.queryUsers();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.queryUsers();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val.size;
        this.pager.page = val.page;
        this.queryUsers();
      },

      /**
       * 获取所有的用户
       */
      async queryUsers() {
        this.listLoading = true;
        if (this.filters.source == "") {
          this.filters.source == null;
        }
        // console.log(1)
        const responseData = await searchUser(this.filters, this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.userList = responseData.data.list || [];
          // console.log(this.userList)
          /*  this.userList = userList.map((userItem) => {
           const role = this.roles && this.roles.find((roleItem) => {
           return roleItem.code == userItem.roleCode;
           });
           userItem.role = role;
           return userItem;
           });*/
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 处理禁用还是启用
       * @param index 当先列的下标
       * @param row   当前列的数据对象
       * @param disabled Boolean true调用禁用  false调用启用
       */
      async handleDisabled(index, row, disabled) {
        let disableData = null;
        if (disabled) {
          disableData = await disabledUser(row.id);
        } else {
          disableData = await enabledUser(row.id);
        }
        if (disableData.errorCode == 0 && disableData.data == "done") {
          row.locked = disabled;
          Notification.success({
            title: "成功",
            message: disabled ? "禁用成功" : "启用成功",
          });
        }
      },
    },
    async created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryUsers();
      // this.checkEditPermission();
    },
    watch: {
      userDetailVisible(newVal) {
        if (newVal === false) {
          this.isEdit = false;
        }
      },
    },
  };
</script>
