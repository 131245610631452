/**
 * Created by xinsw on 16/9/9.
 *
 * 用户service封装
 *
 */
import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 根据用户名搜索
 * @returns {Promise.<*>}
 */
export async function searchUser(
  params = { name: "" },
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("user/byName", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 大屏用户
 * @returns {Promise.<*>}
 */
export async function getBigUser(
  params = { name: "" },
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("user/bigScreen", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建一个用户
 * @param user Object {username:'',mobile	:'',password:'',roleId:'',remarks	:'',name:''}
 * @returns {Promise.<*>}
 */
export async function createUser(user) {
  try {
    return await API.post("user/create", user);
  } catch (error) {
    return error;
  }
}

/**
 * 修改一个用户
 * @param user Object {username:'',mobile	:'',password:'',roleId:'',remarks	:'',name:''}
 * @returns {Promise.<*>}
 */
export async function updateUserInfo(user) {
  try {
    return await API.post("user/update", user);
  } catch (error) {
    return error;
  }
}

/**
 * 删除用户
 * @param ids String
 * @returns {Promise.<*>}
 */
export async function removeUser(ids) {
  try {
    return await API.del("user/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取用户的信息
 * @param id String 用户ID
 * @returns {Promise.<*>}
 */
export async function getUserById(id) {
  try {
    return await API.get("user/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 根据username获取用户信息
 */
export async function getByUsername(username) {
  try {
    return await API.get("user/byUsername?username=" + username);
  } catch (error) {
    return error;
  }
}

/**
 * 禁止用户
 * @param id String 用户ID
 * @returns {Promise.<*>}
 */
export async function disabledUser(id) {
  try {
    return await API.post("user/" + id + "/locked");
  } catch (error) {
    return error;
  }
}

/**
 * 启用用户
 * @param id String 用户ID
 * @returns {Promise.<*>}
 */
export async function enabledUser(id) {
  try {
    return await API.post("user/" + id + "/unlocked");
  } catch (error) {
    return error;
  }
}

/**
 * 修改用户角色和组别
 * @param userInfo Object {userId,roleId,teamId}
 * @returns {Promise.<*>}
 */
export async function updateTeamAndRole(userInfo) {
  try {
    return await API.post("user/updateTeamAndRole", userInfo);
  } catch (error) {
    return error;
  }
}

/**
 * 重置密码
 * @param userId String 用户id
 * @param password  String 密码
 * @returns {Promise.<*>}
 */
export async function resetUserPwd(userId, submitPassword) {
  try {
    return await API.post("user/changePassword", { userId, submitPassword });
  } catch (error) {
    return error;
  }
}

/**
 * 获取当前登录用户的信息
 * @returns {Promise.<*>}
 */
export async function getTempUserInfo() {
  try {
    return await API.get("user/info");
  } catch (error) {
    return error;
  }
}

/**
 * 获取当前登录用户的信息
 * @returns {Promise.<*>}
 */

export async function getPmgsInfo() {
  try {
    return await API.get("permission/info");
  } catch (error) {
    return error;
  }
}

/**
 * 获取用户反馈列表
 * @returns {Promise.<*>}
 */
export async function searchFeedbackByKey(
  key,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("feedback", { ...key, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * id 获取用户反馈
 * @returns {Promise.<*>}
 */
export async function getFeedbackById(id) {
  try {
    return await API.get("feedback/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 删除用户反馈
 * @returns {Promise.<*>}
 */
export async function delFeedback(ids) {
  try {
    return await API.del("feedback/" + ids);
  } catch (error) {
    return error;
  }
}

/*获取月增长用户*/
export async function findUserAddByTime(startTime, endTime) {
  try {
    return await API.get("user/dayStaticByTime", { startTime, endTime });
  } catch (error) {
    return error;
  }
}

/*获取概览数据*/
export async function getStaticsTotal() {
  try {
    return await API.get("staticsRecord/count");
  } catch (error) {
    return error;
  }
}

export async function updateAccPwd(params) {
  try {
    return await API.post("user/changeUsername", params);
  } catch (error) {
    return error;
  }
}

// 我的家列表
export async function getUserHouse(params) {
  try {
    return await API.get("userHouseWithUser", params);
  } catch (error) {
    return error;
  }
}
